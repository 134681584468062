#wrapperbg .bghead{
    background: url(../utils//slider-bg.png) top -200px center no-repeat,linear-gradient(to bottom,#003eaa 40%,#004ec2 60%,#0060df 80%,#0080ff 90%,#00c7ff 100%);
height: auto;
padding-bottom: 150px;
position: relative;
width: 100%;
color: #fff;
    overflow: hidden;
  
    text-align: center;
}
#wrapperbg .bghead::before{
    content: "";
    background-image: url(../utils/wave.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 5223px 186px;
    width: 100%;
    display: block;
    height: 168px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}


#wrapperbg .bghead .mylayout{
    margin-top: 50px;
}
#wrapperbg .bghead .mylayout #tipical h2{
text-align: right;
font-size: 45px;
margin: 0 55px 25px 0;
}
#wrapperbg .bghead .mylayout #tipical p{
    font-size: 25px;
    margin-right: 50px;
    margin-bottom: 25px;
    height: 60px;
}
#wrapperbg .bghead .mylayout #register ul li{
list-style: none;
float: right;
margin: 0 10px;
}

#wrapperbg .bghead .mylayout #register ul li button{
    padding: .5rem 1rem;
    min-width: 150px;
    text-align: center;
  
}
#wrapperbg .bghead .mylayout #register{
    padding-right: 50px;
    margin-top: 35px;
   
}
#wrapperbg .bghead .mylayout #register ul li #first {
    background-color: #16da00;
    border-color: #16da00;
    color: #fff;
    text-shadow: none;
    border: none;
    box-shadow: none;
}
#wrapperbg .bghead .mylayout #register ul li #second {
    background-color: #006ff5;
    border-color: #004aa5;
    color: white ;
    font-weight: 500;
    text-shadow: none;
    margin-left: 5px;
    border: none;
    box-shadow: none;
}
#wrapperbg .bghead .mylayout #callNumber{
    margin-top: 40px;
    
}
#wrapperbg .bghead .mylayout #callNumber p{
    margin-right: 50px;
    text-align: right;
    line-height: 2;
}