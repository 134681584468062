 @import url(../utils/css/all.css); 


.table{
    width: 100%;
    height: auto;
    padding-bottom: 70px;
    background-color: #e1e4e7;
    direction: rtl;
    text-align: center;
    overflow: auto;
}
.table tr td img{
    width: 30px;
}
.table .tablewrapp{
    width: 85%;
    margin: 0 auto;
}
.table table{
   width: 100%;
background-color: white;
overflow-x: auto;
}
.table table tr th{
    border: 1px solid rgb(255, 255, 255);
    padding: 5px;
    background-color: #1562dd;
    color: white;
    font-size: 14px;
    font-weight: 100;
}
.table table tr td{
    border: 1px solid rgb(207, 207, 207);
    padding: 3px 0;
    color: rgb(107, 105, 105);
    font-size: 14px;
    position: relative;
}

.table table tr td:nth-child(6)::before{
    font-family: "Font Awesome 5 Free";
    content: "\f0d7";
    display: inline-block;
  color: green;
    font-weight: 900;
    position: absolute;
    left: 20%;
animation: arrowAnimate 1s infinite ease-in ;
}

.table table tr:nth-child(odd){
    background-color:#f2f2f3 ;
}
.table h3{
   color: #555;
   font-weight: bold;
    padding: 30px 0 0 0;
    font-size: 30px;
}
.table p{
    font-size: 13px;
    margin-bottom: 40px;
}
.table .select{
    display: flex;
    flex-direction: row;
   margin-bottom: 30px;
}
.table .select label{
    color: #444;
    margin-left: 8px;
    font-weight: bold;
}
.table .select select{
    width: 189px;
    background-color: white;
    padding: 0 5px;
    font-size: 15px;
    border-radius: 5px;
    color: rgb(107, 105, 105);
}
/* .table .select #myselect{
width: 30%;
background-color: white;
}
.table .select #myselect .custom-select{
    display: none;
} */

@keyframes arrowAnimate {
    0%{opacity: 0;}
    50%{opacity: 0.5;}
    100%{opacity: 1;}
}