@font-face {
  font-family: Yekan;
  src: url(./Components/utils/BYekan.woff); 
}
*{
  margin: 0;
  padding: 0;
  font-family: Yekan;
}

body {
  margin: 0;
box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}


