#chart{
    padding-bottom: 50px;
}
#chart #firstChart{
    padding: 10px;
}
#chart #firstChart h6{
    margin: 25px 0;
    padding-bottom: 10px;
    border-bottom: 3px solid rgb(219, 48, 48);
    color: rgb(219, 48, 48);
    font-size: 20px;
}
#chart #secondChart{
    padding: 10px;
}

#chart #secondChart h6{
    margin: 25px 0;
    padding-bottom: 10px;
    border-bottom: 3px solid #2c85af;
    color: #2c85af;
    font-size: 20px;
}
#chart .boxTable{
    width: 95%;
    margin:0 auto ;
    box-shadow: 0 0 7px 2px lightgray;
    padding: 10px;
}

#chart .boxTable .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}

#chart .boxTable .title h5{
    color: #2c85af;
    font-size: 18px;
    font-weight: bold;
}
#chart .boxTable .tablelittle table{
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}
#chart .boxTable .tablelittle table thead th{
    font-size: 13px;
}
#chart .boxTable .tablelittle table thead tr{
    border-top: 2px solid #2c85af;
}
#chart .boxTable .tablelittle table th{
    border: 1px solid lightgray;
    font-weight: bold;
}
#chart .boxTable .tablelittle table td{
    border: 1px solid lightgray;
    font-size: 12px;
    color: #878787;
}
#chart .boxTable .tablelittle table th:first-child{
    background-color: #f2f7fa;
    color: #575757;
   
}
#chart .boxTable .tablelittle table td:first-child{
    background-color: #f2f7fa;
    color: #575757;
    font-weight: bold;
}
#chart #between{
    padding: 70px 0;
}
#chart #between h4{
    text-align: right;
    position: relative;
    font-size: 28px;
    color: #686868;
    font-weight: 500;
}
#chart #between h4::before{
    content: "";
    border: 1px solid lightgray;
    position: absolute;
    top: 40px;
    width: 100%;
    left: 0;
    right: 0;
}