.footer{
    background: url(../utils//slider-bg.png) top -200px center no-repeat,linear-gradient(to bottom,#003eaa 40%,#004ec2 60%,#0060df 80%,#0080ff 90%,#00c7ff 100%);
    height: auto;
    position: relative;
    width: 100%;
    color: #fff;
        overflow: hidden;
      
        text-align: center;
}
.footer::before{
    content: "";
    background-image: url(../utils/wave-r1.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 5223px 186px;
    width: 100%;
    display: block;
    height: 168px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}
.footer #innerTicket{
    padding: 160px 0 70px 0;
}
.footer #logo{
    width: 70%;
    margin: 0 auto;
    display: block;
}
.footer #list ul{
padding-left: 0;
text-align: center;
}
.footer #list ul li{
list-style: none;
margin-bottom: 10px;
}
.footer #list ul li a{
    color: white;
    text-decoration: none;
}
.footer #address{
text-align: right;
}

@media only screen and (max-width:768px) {
    .footer #address{
        text-align: center;
        }
        .footer #logo{margin-bottom: 20px;}
        .footer #list{margin-bottom: 20px;}
        .footer #logo{width: 100%;}

        @media only screen and (min-width:480px) and (max-width:768px){
            .footer #logo{width: 60%;}

        }
        @media only screen and (max-width:480px){
            .footer #logo{width: 50%;}
        }
}
