.filmbg{
    margin-top: 100px;
}
.filmbg #titr{
    height: 450px;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
   padding: 10px;
    align-items: flex-end;
}
.filmbg #titr h3{
    text-align: right;
    font-size: 24px;
    margin-bottom: 25px;
    color: #173146;
}
.filmbg #titr p{
    text-align: right;
    color: rgb(165, 165, 165);
    line-height: 2;
}
.filmbg #titr button{
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #173146;
    border-radius: 30px;
    width: 130px;
    transition: 0.5s ease all;
}
.filmbg #titr button:hover{
    background-color:#173146 ;
    color: yellow;
    transition: 0.5s ease all;
}
.filmbg .effectpic{
    height: 450px;
    position: relative;
   
}

video::-webkit-media-controls-enclosure {
    display:none !important;
  }
  video::-moz-media-controls-enclosure {
    display:none !important;
  }

  .filmbg .effectpic .textOver .secondText{
      position: absolute;
      top: 0;
      left: 0;
  }
  .filmbg .effectpic .textOver .secondText h6{
    font-size: 12px;
  
    background-color: #e1e4e7;
  
    float: left;
    padding: 13px;
    border-radius: 20px
  }
 
  .filmbg .effectpic .textOver .secondText p{
position: relative;
    background-image: url(../utils/ConversationBox.png);
height: 158px;
background-repeat: no-repeat;
background-size: contain;
    /* background-color: #f5f6f2;
    padding: 20px;
    border-radius: 30px;
    color: #173146;
    font-size: 14px; */
  }
  .filmbg .effectpic .textOver .secondText p::after{

    content: 'کار ما سرمایه گزاری برای افرادی هست که  \a میخواهند بیش از تورم سود کسب کنند';
    white-space: pre;
font-size: 14px;
position: absolute;
    top: 20px;
    left: 18px;
/* 
content: " ";
position: absolute; */
  }

  .filmbg .effectpic .textOver .secondText p img{
      width: 40px;
      position: absolute;
    top: 57%;
    right: 0;
  }
  