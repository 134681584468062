.bgplayer #picback{
    width: 70%;
    margin: 0 auto;
}
.bgplayer{
    position: relative;
}


.bgplayer .pulsingButton {
    width: auto;
    text-align: center;
    white-space: nowrap;
    display: block;
    margin: 50px auto;
    padding: 20px;
   border: none;
  
    border-radius: 50%;
    border-color:rgb(1, 111, 225);
    background-color: rgb(1, 111, 225);
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    font-size: 22px;
    font-weight: normal;
  
    text-decoration: none;
    color: #ffffff;
    transition: all 300ms ease-in-out;
   position: absolute;
   top: 40%;
   left: 50%;
   transform: translate(-50px,-50px);
  }
  
  .bgplayer .pulsingButton img{
      width: 34px;
      height: 34px;
      padding-left: 5px;
  }
  
  /* Comment-out to have the button continue to pulse on mouseover */
  
  .bgplayer a.pulsingButton:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
    color: #ffffff;
  }
  
  
  /* Animation */
  
  @-webkit-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  
  @-moz-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  
  @-ms-keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  
  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }
  



  
@media only screen and (max-width:480px){
  .bgplayer .pulsingButton{
    padding: 10px;
    left: 55%;
  }

  .bgplayer .pulsingButton img {
    width: 26px;
    height: 20px;
   
}
}