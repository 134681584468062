 .navigation{
    width: 100%;
    height: auto;
    background-color:transparent;
  z-index: 22;
 
} 

.navigation .navbar-nav{
    margin-left: 40px;
    margin-top: 15px;
}
.navigation .navbar-nav a{
    color: white!important;
    font-weight: bold;
    margin-left: 20px;
    text-decoration: none;
}


.navigation #logo{
    width: 240px;
    position: absolute;
    top: 10px;
    right: 30px;
}

@media only screen and (max-width:992px) {
    .navigation #logo{
        width: 180px;
    }
    .navigation .navbar-nav a{
        text-align: right;
        margin-top: 10px;
        margin-right: 30px;
    }
    .navigation .navbar-nav{
        margin-left: 0;
        margin-top: 0;
    }
}